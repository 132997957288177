import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import * as icon from 'react-feather';
import ErrorPage from './Error';
import APP from '../App';
import Login from './Login';
import About from './Pages/About';
import Blog from './Pages/Blog';
import Doctors from './Pages/Doctors';
import General from './Pages/General';
import Departments from './Pages/Departments';
import Specialist from './Pages/Specialist';
import Sliders from './Pages/Sliders';
import Intro from './Pages/Intro';
import Users from './Pages/Users';
import Gallery from './Pages/Gallery';

const Routes = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper full-page">
                    <Login icon={icon} />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <APP><Blog icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sliders",
        element: <APP><Sliders icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/about",
        element: <APP><About icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/blog",
        element: <APP><Blog icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/general",
        element: <APP><General icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/departments",
        element: <APP><Departments icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/specialists",
        element: <APP><Specialist icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/doctors",
        element: <APP><Doctors icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/intro",
        element: <APP><Intro icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/users",
        element: <APP><Users icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/gallery",
        element: <APP><Gallery icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
]
);

export default Routes;