import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import Editor from "./Editor";

const Intro = ({ icon }) => {

    const [data, setData] = useState([]);
    const [id, setId] = useState(null);
    const getData = async () => {
        const { data } = await axios.get('/auth/intro');
        setData(data);
        setId(data.id)
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [selectedFile2, setSelectedFile2] = useState(null);
    const uploadChange2 = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile2(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, [id]);

    const [content, setContent] = useState({});
    const handleContentChange = (name, value) => {
        setContent(prevContent => ({ ...prevContent, [name]: value }));
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Intro</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Intro</h6>
                                </div>
                                <div className="col-md-12 d-flex">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        formData.append('content', content.content);
                                        const data = Object.fromEntries(formData);
                                        axios.put(`/intro/${id}`, data).then(() => {
                                            toast.success('updated successfully');
                                            getData();
                                        })
                                    }} >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Title</label>
                                                    <input type="text" className="form-control" name="title" defaultValue={data.title} required />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">content</label>
                                                <Editor name="content" targetTextareaId="content" defaultValue={data.content} onContentChange={(value) => handleContentChange("content", value)} />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Image 1</label>
                                                    <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                    <input type="hidden" name="image_1" defaultValue={selectedFile ? selectedFile : data.image_1} required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Image 2</label>
                                                    <input type="file" className="form-control" onChange={uploadChange2} accept="application/pdf, image/*" />
                                                    <input type="hidden" name="image_2" defaultValue={selectedFile2 ? selectedFile2 : data.image_2} required />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Update</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Intro;