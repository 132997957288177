import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";

const Doctors = ({ icon }) => {

    const [data, setData] = useState([]);
    const [specialist, setspecialist] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/doctor');
        const { data: specialist } = await axios.get('/auth/specialist');
        setspecialist(specialist)
        setData(data);
    };


    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">doctors</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">doctors List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New doctor</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">New doctor</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/doctor`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('added success');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">full name</label>
                                                            <input type="text" className="form-control" name="full_name" placeholder="Dr. full name" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">specialist</label>
                                                            <select className="form-select" name="specialist" aria-label="1">
                                                                {specialist.map((i, x) => {
                                                                    return (
                                                                        <option value={i.id}>{i.title}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Certification</label>
                                                            <input type="text" className="form-control" name="cert" placeholder="Certification" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">contact</label>
                                                            <input type="text" className="form-control" name="contact" placeholder="contact" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">image</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                        <button type="submit" className="btn btn-primary">submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>image</th>
                                            <th>Dr.</th>
                                            <th>Certification</th>
                                            <th>Contact</th>
                                            <th>option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={`../../${item.image}`} alt={item.full_name} className="img-fluid" /></td>
                                                    <td>Dr. {item.full_name}</td>
                                                    <td>{item.cert}</td>
                                                    <td>{item.contact}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-danger"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: 'warning !',
                                                                    text: "you can't restore once you delete it",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'yes, remove it !',
                                                                    cancelButtonText: 'no, close!'
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/doctor/${item.id}`).then(() => {
                                                                            getData();
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                            <icon.Trash size="18" />
                                                        </button>
                                                        <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">Edit</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                    </div>
                                                                    <form onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        const data = Object.fromEntries(formData);
                                                                        axios.put(`/doctor/${item.id}`, data).then(() => {
                                                                            window.$(`#edit${item.id}`).modal('hide');
                                                                            toast.success('updated successfully');
                                                                            getData();
                                                                        })
                                                                    }} >
                                                                        <div className="modal-body">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">full name</label>
                                                                                <input type="text" className="form-control" name="full_name" defaultValue={item.full_name} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">specialist</label>
                                                                                <select className="form-select" name="specialist" defaultValue={item.specialist}>
                                                                                    {specialist.map((i, x) => {
                                                                                        return (
                                                                                            <option value={i.id}>{i.title}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Certification</label>
                                                                                <input type="text" className="form-control" name="cert" defaultValue={item.cert} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">contact</label>
                                                                                <input type="text" className="form-control" name="contact" defaultValue={item.contact} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">image</label>
                                                                                <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                                <input type="hidden" name="image" defaultValue={selectedFile ? selectedFile : item.image} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                                            <button type="submit" className="btn btn-primary">update</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Doctors;