import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Editor from "./Editor";

const About = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/about');
        setData(data);
        if (data.length > 0) {
            axios.get("/auth/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true
                        })
                    }
                }
            })
        }
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    const [branch, setBranch] = useState([]);
    const getBranch = async () => {
        const { data } = await axios.get('/auth/branch');
        setBranch(data)
    }

    const [tab, setTab] = useState([]);
    const getTab = async () => {
        const { data } = await axios.get('/auth/tab');
        setTab(data)
    }

    useEffect(() => {
        getTab();
        getData();
        getBranch();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [content, setContent] = useState({});
    const handleContentChange = (name, value) => {
        setContent(prevContent => ({ ...prevContent, [name]: value }));
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">بریکارەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">دەربارە</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark mx-2" data-bs-toggle="modal" data-bs-target="#branch">
                                        <icon.Share2 />
                                        <span className="ms-2">لقەکان</span>
                                    </button>
                                    <div className="modal fade" id="branch" tabIndex={-1} aria-labelledby="branch" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">لقەکان</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <div className="modal-body">
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        const formData = new FormData(e.target);
                                                        const data = Object.fromEntries(formData);
                                                        axios.post(`/branch`, data).then(() => {
                                                            toast.success('added successfully');
                                                            getBranch();
                                                        })
                                                    }} >
                                                        <div className="mb-3">
                                                            <label className="form-label">ناو (کوردی)</label>
                                                            <input type="text" className="form-control" name="name_krd" placeholder="ناو بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناو (عەرەبی)</label>
                                                            <input type="text" className="form-control" name="name_ar" placeholder="ناو بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناو (ئینگلیزی)</label>
                                                            <input type="text" className="form-control" name="name_en" placeholder="ناو بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <button type="submit" className="btn btn-primary">زیادکردن</button>
                                                        </div>
                                                    </form>
                                                    <div className="table-resposive">
                                                        <table className="table table-striped table-sm" style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>لق</th>
                                                                    <th>بژاردە</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {branch.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.name_krd}</td>
                                                                            <td>
                                                                                <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                                                    Swal.fire({
                                                                                        title: 'وریابە !',
                                                                                        text: "بە سڕینەوەی ئەم داتایە ناتونیت پاشگەزبیتەوە",
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonColor: '#3085d6',
                                                                                        cancelButtonColor: '#d33',
                                                                                        confirmButtonText: 'بەلێ, سڕینەوە !',
                                                                                        cancelButtonText: 'نەخێر, داخستن!'
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            axios.delete(`/branch/${item.id}`).then(() => {
                                                                                                getBranch();
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                                    <icon.Trash size="18" />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-sm btn-dark mx-2" data-bs-toggle="modal" data-bs-target="#tabs">
                                        <icon.Table />
                                        <span className="ms-2">تابەکان</span>
                                    </button>
                                    <div className="modal fade" id="tabs" tabIndex={-1} aria-labelledby="tabs" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">تابەکان نوێ</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>

                                                <div className="modal-body">
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        const formData = new FormData(e.target);
                                                        const data = Object.fromEntries(formData);
                                                        axios.post(`/tab`, data).then(() => {
                                                            toast.success('added successfully');
                                                            getTab();
                                                        })
                                                    }} >
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (کوردی)</label>
                                                            <input type="text" className="form-control" name="title_krd" placeholder="ناونیشان بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (عەرەبی)</label>
                                                            <input type="text" className="form-control" name="title_ar" placeholder="ناونیشان بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (ئینگلیزی)</label>
                                                            <input type="text" className="form-control" name="title_en" placeholder="ناونیشان بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (کوردی)</label>
                                                            <textarea className="form-control" name="content_krd" rows={3} placeholder="ناوەڕۆک بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (عەرەبی)</label>
                                                            <textarea className="form-control" name="content_ar" rows={3} placeholder="ناوەڕۆک بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (ئینگلیزی)</label>
                                                            <textarea className="form-control" name="content_en" rows={3} placeholder="ناوەڕۆک بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <button type="submit" className="btn btn-primary">زیادکردن</button>
                                                        </div>
                                                    </form>
                                                    <div className="table-resposive">
                                                        <table className="table table-striped table-sm" style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>سەردێڕی تاب</th>
                                                                    <th>بژاردە</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tab.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.title_krd}</td>
                                                                            <td>
                                                                                <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                                                    Swal.fire({
                                                                                        title: 'وریابە !',
                                                                                        text: "بە سڕینەوەی ئەم داتایە ناتونیت پاشگەزبیتەوە",
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonColor: '#3085d6',
                                                                                        cancelButtonColor: '#d33',
                                                                                        confirmButtonText: 'بەلێ, سڕینەوە !',
                                                                                        cancelButtonText: 'نەخێر, داخستن!'
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            axios.delete(`/tab/${item.id}`).then(() => {
                                                                                                getTab();
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                                    <icon.Trash size="18" />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">دەربارەیەکی نوێ</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">دەربارەیەکی نوێ</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    formData.append('content_krd', content.content_krd);
                                                    formData.append('content_ar', content.content_ar);
                                                    formData.append('content_en', content.content_en);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/about`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('added successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (کوردی)</label>
                                                            <input type="text" className="form-control" name="title_krd" placeholder="ناونیشان بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (عەرەبی)</label>
                                                            <input type="text" className="form-control" name="title_ar" placeholder="ناونیشان بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (ئینگلیزی)</label>
                                                            <input type="text" className="form-control" name="title_en" placeholder="ناونیشان بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (کوردی)</label>
                                                            <Editor name="content_ar" targetTextareaId="content_krd" onContentChange={(value) => handleContentChange("content_krd", value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (عەرەبی)</label>
                                                            <Editor name="content_ar" targetTextareaId="content_ar" onContentChange={(value) => handleContentChange("content_ar", value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (ئینگلیزی)</label>
                                                            <Editor name="content_en" targetTextareaId="content_en" onContentChange={(value) => handleContentChange("content_en", value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">لق</label>
                                                            <select className="form-select" name="branch_id">
                                                                {branch.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.id}>{item.name_krd}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">وێنە</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary">بڵاوکردن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>سەردێڕ</th>
                                            <th>بژاردە</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.title_krd}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-danger"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: 'warning !',
                                                                    text: "you can't restore once you delete it",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'yes, remove it !',
                                                                    cancelButtonText: 'no, close!'
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/about/${item.id}`).then(() => {
                                                                            getData();
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                            <icon.Trash size="18" />
                                                        </button>
                                                        <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">دەستکاریکردن</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                    </div>
                                                                    <form onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        formData.append('content_krd', content.content_krd);
                                                                        formData.append('content_ar', content.content_ar);
                                                                        formData.append('content_en', content.content_en);
                                                                        const data = Object.fromEntries(formData);
                                                                        axios.put(`/about/${item.id}`, data).then(() => {
                                                                            window.$(`#edit${item.id}`).modal('hide');
                                                                            toast.success('updated successfully');
                                                                            getData();
                                                                        })
                                                                    }} >
                                                                        <div className="modal-body">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناونیشان (کوردی)</label>
                                                                                <input type="text" className="form-control" name="title_krd" defaultValue={item.title_krd} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناونیشان (عەرەبی)</label>
                                                                                <input type="text" className="form-control" name="title_ar" defaultValue={item.title_ar} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناونیشان (ئینگلیزی)</label>
                                                                                <input type="text" className="form-control" name="title_en" defaultValue={item.title_en} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناوەڕۆک (کوردی)</label>
                                                                                <Editor name="content_ar" targetTextareaId="content_krd" defaultValue={item.content_krd} onContentChange={(value) => handleContentChange("content_krd", value)} />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناوەڕۆک (عەرەبی)</label>
                                                                                <Editor name="content_ar" targetTextareaId="content_ar" defaultValue={item.content_ar} onContentChange={(value) => handleContentChange("content_ar", value)} />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناوەڕۆک (ئینگلیزی)</label>
                                                                                <Editor name="content_en" targetTextareaId="content_en" defaultValue={item.content_en} onContentChange={(value) => handleContentChange("content_en", value)} />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">لق</label>
                                                                                <select className="form-select" name="branch_id">
                                                                                    {branch.map((obj, x) => {
                                                                                        return (
                                                                                            <option key={x} value={obj.id}>{obj.name_krd}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">وێنە</label>
                                                                                <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                                                <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                                            <button type="submit" className="btn btn-primary">sumbit</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default About;